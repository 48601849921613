<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat dense>
          <v-toolbar-title>New Item Property</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon to="/inventory/item-properties">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- editor -->
            <editor :initial="dataItem" @data="save"></editor>
          <!-- end -->
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
import Editor from '../_components/editor.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Editor
  },
  computed: {
    ...mapGetters(['user'])
  },
  data: () => ({
    dataItem: {}
  }),
  methods: {
    save(data) {
      const url = "/itemsproperty";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then(res => {
          if (res.ResultCode == 1200) {
            self.$router.push('/inventory/item-properties');
          } else {
            this.$refs.snackbar.show(res.msg, "red");
          }
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  }
};
</script>